<template>
	<div>
		<div class="navbar-brand">
			<a :href="mainUrl" v-if="loginUserId != ''">
				<img src="/images/a-safe-logo.png" alt="" style="height: 68px; image-rendering: -webkit-optimize-contrast" />
			</a>
			<!-- <a href="/main" v-if="loginUserId != '' && menuGubun == '02'">
				<img src="/images/a-safe-logo.png" alt="" style="height: 68px; image-rendering: -webkit-optimize-contrast" />
			</a> -->
			<a href="/" v-if="loginUserId == ''">
				<img src="/images/a-safe-logo.png" alt="" style="height: 68px; image-rendering: -webkit-optimize-contrast" />
			</a>
		</div>
		<div id="topmenu" style="clear: both">
			<div class="navbar navbar-inverse" style="border: none; height: 48px">
				<!-- 모바일 용 메뉴 (화면 가로폭이 좁아졌을 때에만 보임) -->
				<div class="navbar-collapse collapse" id="navbar-mobile" style="height: 48px !important">
					<div class="nav navbar-nav navbar-menu" style="margin-left: 40px">
						<li>
							<a href="/renew-main">
								<i class="icon-paragraph-justify3"></i>
							</a>
						</li>
						<!-- 건설메뉴 -->
						<ul v-if="menuGubun == '02'">
							<!-- <li>
								<a href="/main">
									<span>홈</span>
								</a>
							</li> -->
							<li v-if="hasAuthority(['/viewAccidentPrevent.do'])">
								<a @click="loadView('accidentPrevent', $event)" data-desc="재해예방조치">
									<span>재해예방조치</span>
								</a>
							</li>
							<li v-if="hasAuthority(['/viewDangerWarnWorkOccur.do', '/aiDistinguish.do'])">
								<a href="#">
									<span>이력조회</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewDangerWarnWorkOccur.do'])">
											<a @click="loadView('dangerWarnWorkOccur', $event)" data-desc="발생이력">
												<span>발생이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/aiDistinguish.do'])">
											<a @click="loadView('aiDistinguish', $event)" data-desc="AI판별이력">
												<span>AI판별이력</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li
								v-if="
									hasAuthority([
										'/viewTree.do',
										'/viewSiteStatistic.do',
										'/viewAccidentStatistic.do',
										'/viewObjectDangerOccurStatistic.do',
										'/viewObjectDangerOccurStatisticBar.do',
										'/viewObjectDangerOccurStatisticLine2.do',
										'/viewObjectDangerOccurStatisticBar2.do',
									])
								"
							>
								<a href="#">
									<span>조회/통계</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewTree.do'])">
											<a @click="loadView('tree', $event)" data-desc="현장별사용자조회">
												<span>현장별 사용자 조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSiteStatistic.do'])">
											<a @click="loadView('siteStatistic', $event)" data-desc="현장별통계">
												<span>현장별통계</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewAccidentStatistic.do'])">
											<a @click="loadView('accidentStatistic', $event)" data-desc="재해유형별통계">
												<span>재해유형별통계</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
											<a @click="loadView('objectDangerOccurStatistic', $event)" data-desc="상황별 발생 추이">
												<span>상황별 발생 추이</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticBar.do'])">
											<a @click="loadView('objectDangerOccurStatisticBar', $event)" data-desc="상황별 발생 건수">
												<span>상황별 발생 건수</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticLine2.do'])">
											<a @click="loadView('objectDangerOccurStatisticLine2', $event)" data-desc="상황별 발생 추이(본사)">
												<span>상황별 발생 추이(본사)</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticBar2.do'])">
											<a @click="loadView('objectDangerOccurStatisticBar2', $event)" data-desc="상황별 발생 건수(본사)">
												<span>상황별 발생 건수(본사)</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewExemplaryCase.do'])">
								<a @click="loadView('exemplaryCase', $event)" data-desc="모범사례">
									<span>모범사례</span>
								</a>
							</li>
							<!-- <li
								v-if="
									hasAuthority([
										'/viewNotice.do',
										'/viewCompany.do',
										'/viewSite.do',
										'/viewRoleCode.do',
										'/viewRoleGroup.do',
										'/viewUser.do',
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewObject.do',
										'/viewTarget.do',
										'/viewObjectTarget.do',
										'/viewApiCallErrLog.do',
										'/viewCctvDangerAreaConfig.do',
									])
								"
							> -->
							<li
								v-show="
									hasAuthority([
										'/viewCompany.do',
										'/viewSite.do',
										'/viewUser.do',
										'/viewRegisterUser.do',
										'/viewSettingPushAlarm.do',
										'/viewNotice.do',
										'/viewMenu.do',
										'/viewRoleGroup.do',
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewApiCallErrLog.do',
										'/viewObject.do',
										'/viewTarget.do',
										'/viewObjectTarget.do',
										'/viewWarnWork.do',
										'/viewBasicAiConfig.do',
										'/viewAiConfigDistigshSutbDgreCctv.do',
										'/viewCctvDangerAreaConfig.do',
										'/viewCctvAiConfig.do',
										'/viewSpeakerAiConfig.do',
										'/viewCctv.do',
										'/viewSpeaker.do',
										'/viewSpeakerSoundsrc.do',
										'/viewSpeakerCctv.do',
										'/viewCctvBySafeManager.do',
									])
								"
							>
								<a @mouseover="setSubMenuLocation()">
									<span>관리</span>
								</a>
								<div ref="navSubMultiple" class="Nav_Sub_Multiple">
									<div class="mng_menu" v-if="hasAuthority(['/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewRegisterUser.do'])">
										<ul>
											<li>
												<div>조직관리</div>
											</li>
											<li v-if="hasAuthority(['/viewCompany.do'])">
												<a style="cursor: pointer" @click="loadView('company', $event)" data-desc="업체관리">
													<span>업체관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSite.do'])">
												<a style="cursor: pointer" @click="loadView('site', $event)" data-desc="현장관리">
													<span>현장관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewUser.do'])">
												<a style="cursor: pointer" @click="loadView('user', $event)" data-desc="사용자관리">
													<span>사용자관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewRegisterUser.do'])">
												<a style="cursor: pointer" @click="loadView('registerUser', $event)" data-desc="사용자가입승인">
													<span>사용자가입승인</span>
												</a>
											</li>
										</ul>
									</div>
									<div
										class="mng_menu"
										v-if="
											hasAuthority([
												'/viewNotice.do',
												'/viewMenu.do',
												'/viewRoleGroup.do',
												'/viewGrpCode.do',
												'/viewCommCode.do',
												'/viewApiCallErrLog.do',
												'/viewSettingPushAlarm.do',
											])
										"
									>
										<ul>
											<li>
												<div>시스템관리</div>
											</li>
											<li v-if="hasAuthority(['/viewNotice.do'])">
												<a style="cursor: pointer" @click="loadView('notice', $event)" data-desc="공지사항">
													<span>공지사항</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewMenu.do'])">
												<a style="cursor: pointer" @click="loadView('menu', $event)" data-desc="메뉴코드관리">
													<span>메뉴코드관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewRoleGroup.do'])">
												<a style="cursor: pointer" @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
													<span>권한그룹관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewGrpCode.do'])">
												<a style="cursor: pointer" @click="loadView('grpCode', $event)" data-desc="공통그룹코드관리">
													<span>공통그룹코드관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewCommCode.do'])">
												<a style="cursor: pointer" @click="loadView('commCode', $event)" data-desc="공통코드관리">
													<span>공통코드관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewApiCallErrLog.do'])">
												<a style="cursor: pointer" @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
													<span>API호출오류로그조회</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSettingPushAlarm.do'])">
												<a style="cursor: pointer" @click="loadView('settingPushAlarm', $event)" data-desc="푸시알림관리">
													<span>푸시알림관리</span>
												</a>
											</li>
										</ul>
									</div>
									<div class="mng_menu" v-if="hasAuthority(['/viewObject.do', '/viewTarget.do', '/viewObjectTarget.do', '/viewWarnWork.do'])">
										<ul>
											<li>
												<div>대상물관리</div>
											</li>
											<li v-if="hasAuthority(['/viewObject.do'])">
												<a style="cursor: pointer" @click="loadView('object', $event)" data-desc="대상물관리">
													<span>대상물관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewTarget.do'])">
												<a style="cursor: pointer" @click="loadView('target', $event)" data-desc="조치대상관리">
													<span>조치대상관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewObjectTarget.do'])">
												<a style="cursor: pointer" @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
													<span>대상물조치대상관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewWarnWork.do'])">
												<a style="cursor: pointer" @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
													<span>위험대상물조합관리</span>
												</a>
											</li>
										</ul>
									</div>
									<div
										class="mng_menu"
										v-if="
											hasAuthority([
												'/viewBasicAiConfig.do',
												'/viewAiConfigDistigshSutbDgreCctv.do',
												'/viewCctvDangerAreaConfig.do',
												'/viewCctvAiConfig.do',
												'/viewSpeakerAiConfig.do',
												'/viewSiteTrespassConfig.do',
											])
										"
									>
										<ul>
											<li>
												<div>AI관리</div>
											</li>
											<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
												<a style="cursor: pointer" @click="loadView('cctvAiConfig', $event)" data-desc="CCTV 대상물 설정">
													<span>CCTV 대상물 설정</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewBasicAiConfig.do'])">
												<a style="cursor: pointer" @click="loadView('basicAiConfig', $event)" data-desc="위험 판단 기준 설정">
													<span>위험 판단 기준 설정</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewAiConfigDistigshSutbDgreCctv.do'])">
												<a style="cursor: pointer" @click="loadView('aiConfigDistigshSutbDgreCctv', $event)" data-desc="AI-threshold 설정">
													<span>AI-threshold 설정</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
												<a style="cursor: pointer" @click="loadView('cctvDangerAreaConfig', $event)" data-desc="위험지역 설정">
													<span>위험지역 설정</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSiteTrespassConfig.do'])">
												<a style="cursor: pointer" @click="loadView('siteTrespassConfig', $event)" data-desc="침입탐지 설정">
													<span>침입탐지 설정</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSpeakerAiConfig.do'])">
												<a style="cursor: pointer" @click="loadView('speakerAiConfig', $event)" data-desc="스피커대상물 설정">
													<span>스피커대상물 설정</span>
												</a>
											</li>
										</ul>
									</div>
									<div
										class="mng_menu"
										v-if="
											hasAuthority(['/viewCctv.do', '/viewSpeaker.do', '/viewSpeakerSoundsrc.do', '/viewSpeakerCctv.do', '/viewCctvBySafeManager.do'])
										"
									>
										<ul>
											<li>
												<div>기기관리</div>
											</li>
											<li v-if="hasAuthority(['/viewCctv.do'])">
												<a style="cursor: pointer" @click="loadView('cctv', $event)" data-desc="CCTV관리">
													<span>CCTV관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewCctvBySafeManager.do'])">
												<a style="cursor: pointer" @click="loadView('cctvBySafeManager', $event)" data-desc="CCTV 설치장소 변경">
													<span>CCTV 설치장소 변경</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSpeaker.do'])">
												<a style="cursor: pointer" @click="loadView('speaker', $event)" data-desc="스피커관리">
													<span>스피커관리</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSpeakerCctv.do'])">
												<a style="cursor: pointer" @click="loadView('speakerCctv', $event)" data-desc="스피커-CCTV 관계 설정">
													<span>스피커-CCTV 관계 설정</span>
												</a>
											</li>
											<li v-if="hasAuthority(['/viewSpeakerSoundsrc.do'])">
												<a style="cursor: pointer" @click="loadView('speakerSoundsrc', $event)" data-desc="스피커음원관리">
													<span>스피커음원관리</span>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li>
								<a href="#">
									<span>모니터링</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li>
											<a @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
												<span>현장 모니터링</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
						<!-- 현대제철용 메뉴 -->
						<ul v-if="menuGubun == '01'">
							<li v-if="hasAuthority(['/viewDangerOccur.do', '/viewWarnWorkOccur.do'])">
								<a href="#">
									<span>위험발생이력</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewDangerOccur.do'])">
											<a @click="loadView('dangerOccur', $event)" data-desc="위험발생이력">
												<span>위험발생이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewDangerOccur.do'])">
											<a @click="loadView('dangerOccurVideo', $event)" data-desc="위험발생이력">
												<span>위험발생이력-비디오</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
											<a @click="loadView('warnWorkOccur', $event)" data-desc="주의작업이력">
												<span>주의작업이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
											<a @click="loadView('warnWorkOccurVideo', $event)" data-desc="주의작업이력">
												<span>주의작업이력-비디오</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewTree.do', '/viewObjectDangerOccurStatistic.do'])">
								<a href="#">
									<span>조회/통계</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewTree.do'])">
											<a @click="loadView('tree', $event)" data-desc="현장별사용자조회">
												<span>현장별 사용자 조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
											<a @click="loadView('objectDangerOccurStatistic', $event)" data-desc="대상물별위험통계">
												<span>대상물별위험통계-Line</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
											<a @click="loadView('objectDangerOccurStatisticBar', $event)" data-desc="대상물별위험통계">
												<span>대상물별위험통계-Bar</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewNotice.do', '/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewMenu.do', '/viewRoleGroup.do'])">
								<a href="#">
									<span>조직관리</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewNotice.do'])">
											<a style="cursor: pointer" @click="loadView('notice', $event)" data-desc="공지사항">
												<span>공지사항</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCompany.do'])">
											<a style="cursor: pointer" @click="loadView('company', $event)" data-desc="업체관리">
												<span>업체관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSite.do'])">
											<a @click="loadView('site', $event)" data-desc="현장관리">
												<span>현장관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewUser.do'])">
											<a @click="loadView('user', $event)" data-desc="사용자관리">
												<span>사용자관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewRegisterUser.do'])">
											<a @click="loadView('registerUser', $event)" data-desc="사용자가입승인">
												<span>사용자가입승인</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewMenu.do'])">
											<a @click="loadView('menu', $event)" data-desc="메뉴코드관리">
												<span>메뉴코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewRoleGroup.do'])">
											<a @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
												<span>권한그룹관리</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li
								v-if="
									hasAuthority([
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewObject.do',
										'/viewTarget.do',
										'/viewObjectTarget.do',
										'/viewWarnWork.do',
										'/viewCctv.do',
										'/viewBasicAiConfig.do',
										'/viewCctvAiConfig.do',
										'/viewApiCallErrLog.do',
										'/viewCctvDangerAreaConfig.do',
										'/viewSiteTrespassConfig.do',
									])
								"
							>
								<a href="#">
									<span>AI/데이터관리</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewGrpCode.do'])">
											<a style="cursor: pointer" @click="loadView('grpCode', $event)" data-desc="그룹코드관리">
												<span>그룹코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCommCode.do'])">
											<a @click="loadView('commCode', $event)" data-desc="공통코드관리">
												<span>공통코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObject.do'])">
											<a @click="loadView('object', $event)" data-desc="대상물관리">
												<span>대상물관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewTarget.do'])">
											<a style="cursor: pointer" @click="loadView('target', $event)" data-desc="조치대상관리">
												<span>조치대상관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectTarget.do'])">
											<a @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
												<span>대상물조치대상관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWork.do'])">
											<a @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
												<span>위험대상물조합관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctv.do'])">
											<a @click="loadView('cctv', $event)" data-desc="CCTV관리">
												<span>CCTV관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
											<a @click="loadView('cctvAiConfig', $event)" data-desc="CCTV 대상물 설정">
												<span>CCTV 대상물 설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewBasicAiConfig.do'])">
											<a @click="loadView('basicAiConfig', $event)" data-desc="위험 판단 기준 설정">
												<span>위험 판단 기준 설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewApiCallErrLog.do'])">
											<a @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
												<span>API호출오류로그조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
											<a @click="loadView('cctvDangerAreaConfig', $event)" data-desc="위험지역 설정">
												<span>위험지역 설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSiteTrespassConfig.do'])">
											<a @click="loadView('siteTrespassConfig', $event)" data-desc="침입탐지 설정">
												<span>침입탐지 설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSpeaker.do'])">
											<a @click="loadView('speaker', $event)" data-desc="스피커관리">
												<span>스피커관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSpeakerSoundsrc.do'])">
											<a @click="loadView('speakerSoundsrc', $event)" data-desc="스피커음원관리">
												<span>스피커음원관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSpeakerCctv.do'])">
											<a @click="loadView('speakerCctv', $event)" data-desc="스피커-CCTV 관계 설정">
												<span>스피커-CCTV 관계 설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSpeakerAiConfig.do'])">
											<a @click="loadView('speakerAiConfig', $event)" data-desc="스피커대상물 설정">
												<span>스피커대상물 설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvBySafeManager.do'])">
											<a @click="loadView('cctvBySafeManager', $event)" data-desc="CCTV 설치장소 관리">
												<span>CCTV 설치장소 관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewAiConfigDistigshSutbDgreCctv.do'])">
											<a @click="loadView('aiConfigDistigshSutbDgreCctv', $event)" data-desc="AI-threshold 설정">
												<span>AI-threshold 설정</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<a @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
									<span>모니터링</span>
								</a>
								<!-- <a href="/dashboard" data-desc="대시보드">
									<span>대시보드</span>
								</a> -->
							</li>
						</ul>
					</div>
					<ul class="nav navbar-nav navbar-right" style="height: 48px">
						<li class="dropdown dropdown-user" v-if="loginUserId != ''">
							<a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
								<i class="icon-user-tie"></i>
								<span>{{ loginUserId }}</span>
								<i class="caret"></i>
							</a>
							<ul class="dropdown-menu dropdown-menu-right">
								<!-- <li>
									<a data-toggle="modal" data-target="#pw_change_popup">
										<i class="icon-user-check"></i>
										비밀번호 변경
									</a>
								</li>
								<li class="divider"></li> -->
								<li>
									<a @click="logout">
										<i class="icon-switch2"></i>
										로그아웃
									</a>
									<a @click="changeSiteId">
										<i class="icon-sync"></i>
										현장변경
									</a>
								</li>
							</ul>
						</li>
						<li class="dropdown dropdown-user" v-else>
							<a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
								<i class="icon-user-tie"></i>
								<span>Anonymous</span>
								<i class="caret"></i>
							</a>
							<ul class="dropdown-menu dropdown-menu-right">
								<!-- <li class="divider"></li> -->
								<li>
									<a href="/login">
										<i class="icon-switch2"></i>
										로그인
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<!-- /모바일 용 메뉴 (화면 가로폭이 좁아졌을 때에만 보임) -->
			</div>

			<div class="modal fade" ref="modal" sites>
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">현장 선택</h5>
						</div>
						<div class="modal-body">
							<select class="form-control" v-model="selectSite" style="font-size: 16px">
								<option value>선택</option>
								<option v-for="(option, index) in selectOption" :value="index" :key="index">
									{{ option.siteNm }}
								</option>
							</select>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary pull-right" @click="selectBtn" selectBtn>확인</button>
						</div>
					</div>
				</div>
			</div>

			<!-- 비밀번호 변경 팝업 -->
			<!-- <div id="pw_change_popup" class="modal fade">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<h5 class="modal-title">비밀번호 변경</h5>
			</div>
			<form action="#" class="form-horizontal">
				<div class="modal-body">
				<div class="form-group">
					<label class="control-label col-sm-5">현재 비밀번호</label>
					<div class="col-sm-7">
					<input type="password" :placeholder="fieldMap.password" class="form-control" v-model="password"
						data-vv-name="fieldMap.password" v-validate="validationRule.password" />
					<div class="form-control-feedback" style="right: 10px;"><i class="icon-lock text-muted"></i></div>
					<able-field-error field="fieldMap.password"></able-field-error>
					</div>
				</div>

				<div class="form-group">
					<label class="control-label col-sm-5">새 비밀번호</label>
					<div class="col-sm-7">
					<input type="password" name="newPassword" ref="newPassword" :placeholder="fieldMap.newPassword" class="form-control" v-model="newPassword"
						data-vv-name="fieldMap.newPassword" v-validate="validationRule.newPassword" />
					<div class="form-control-feedback" style="right: 10px;"><i class="icon-lock text-muted"></i></div>
					<able-field-error field="fieldMap.newPassword"></able-field-error>
					</div>
				</div>

				<div class="form-group">
					<label class="control-label col-sm-5">새 비밀번호 확인</label>
					<div class="col-sm-7">
					<input type="password" :placeholder="fieldMap.newPasswordRe" class="form-control" v-model="newPasswordRe"
						data-vv-name="fieldMap.newPasswordRe" v-validate="validationRule.newPasswordRe" />
					<div class="form-control-feedback" style="right: 10px;"><i class="icon-lock text-muted"></i></div>
					<able-field-error field="fieldMap.newPasswordRe"></able-field-error>
					</div>
				</div>
				</div>
				<div class="modal-footer">
				<button type="button" class="btn btn-link" data-dismiss="modal">닫기</button>
				<button type="button" class="btn btn-primary" @click="changePassword">비밀번호 변경</button>
				</div>
			</form>
			</div>
		</div>
		</div> -->
			<!-- //비밀번호 변경 팝업 -->
		</div>
	</div>
	<!-- /main navbar -->
</template>

<style scoped>
.mng_menu {
	width: 150px !important;
	background: #263238;
}
.mng_menu > ul {
	padding-left: 0px !important;
}

.mng_menu > ul > li:hover > a {
	font-weight: bold;
	background: #1d272b;
}
.Nav_Sub_Multiple {
	position: absolute;
	width: 100%;
	left: 0px;
	z-index: 99;
	top: 48px;
	display: none;
}
.Nav_Sub_Multiple ul > li:first-child {
	background: #35363b;
}

.navbar-menu > ul li:hover .Nav_Sub_Multiple {
	display: flex !important;
	width: 100% !important;
}
.navbar-menu > ul li {
	list-style: none;
	/* float: left; */
	width: 150px;
	position: relative;
	display: inline-block;
}

.navbar-menu > ul li > a {
	color: #fff;
	display: block;
	width: 150px;
	height: 48px;
	padding-top: 1px;
}

.navbar-menu {
	width: 1200px;
	line-height: 48px;
	margin: 0 auto;
	text-align: center;
}
.navbar-menu > ul > li > .hidden-ul {
	width: 150px;
	max-height: 0px;
	overflow: hidden;
	position: absolute;
	background: #263238;
	text-align: center;
	z-index: 1;
	transition: max-height 0.3s;
}
.navbar-menu > ul > li:hover > .hidden-ul {
	max-height: 850px;
	z-index: 1;
	transition: max-height 0.3s;
}
.hidden-ul > ul > li:hover > a {
	font-weight: bold;
	background: #1d272b;
}
.navbar-menu > ul > li {
	background: #37474f;
	transition: all 0.3s;
}
.navbar-menu > ul > li:hover {
	background: #314047;
}
.hidden-ul > ul > li > a {
	display: block;
	width: 150px;
	margin-left: -25px;
}
/* #management {
	display: flex;
	width: 750px !important;
	margin-left: -300px;
}
#management > ul > li:first-child {
	margin-left: -25px;
	background: rgba(0, 0, 0, 0.3);
}
#management > ul {
	width: 150px;
} */
</style>
<script>
import jwt from 'jsonwebtoken';
import VueCookies from 'vue-cookies';
import apiIndex from '../../api/index';

let axiosExtention;

export default {
	data: () => ({
		loginUserNm: '',
		loginUserId: '',
		loginUserMenuList: [],
		menuGubun: '',
		mainUrl: '/',
		selectSite: '', // 현재 선택한 현장의 key value
		selectOption: [], // 셀렉트 박스 값(로그인할 유저의 현장들)
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.getCommCodeList();
		this.getLoginUser();
	},
	mounted() {
		this.menuGubun = process.env.VUE_APP_MENU_GUBUN;
		this.mainUrl = apiIndex.mainUrl;
	},
	methods: {
		setSubMenuLocation() {
			let mngMenuWrapper = this.$refs.navSubMultiple;
			let basicWidth = mngMenuWrapper.getBoundingClientRect().width;
			let halfMenuLength = Math.trunc(document.getElementsByClassName('mng_menu').length / 2);
			mngMenuWrapper.style.marginLeft = -(basicWidth * halfMenuLength) + 'px';
		},
		getLoginUser() {
			// jwt 토큰에서 로그인 유저 아이디 가져오기
			let accessToken = VueCookies.get('V2_ACCESS_TOKEN');
			let decodedAccessToken = jwt.decode(accessToken);
			this.loginUserId = decodedAccessToken.userId;
			this.loginUserMenuList = JSON.parse(decodedAccessToken.userMenu);
			sessionStorage.setItem('loginUserInfo', JSON.stringify(decodedAccessToken));
		},
		async loadView(name, e) {
			// name: 연결 Url 혹은 name / e: click Event 정보(e는 필요하면 사용 - 사용 안하면 지워주세요)
			// ex) name: exemplaryCase(모범사례 - 목록) name에 따라
			// if / switch로 분기하여 정보 조회 url, 연결 화면 url 을 지정하여 사용
			// 또는 @click마다 function 호출 다르게 적용
			console.log('loadView', 'name', name, 'e', e, 'e.target', e.target);

			let url = '';
			if (name == 'accidentPrevent') url = apiIndex.accidentPrevent.viewAccidentPrevent;
			else if (name == 'warnWorkOccur') url = apiIndex.warnWorkOccur.viewWarnWorkOccur;
			else if (name == 'warnWorkOccurVideo') url = apiIndex.warnWorkOccur.viewWarnWorkOccurVideo;
			else if (name == 'dangerOccur') url = apiIndex.dangerOccur.viewDangerOccur;
			else if (name == 'dangerOccurVideo') url = apiIndex.dangerOccur.viewDangerOccurVideo;
			else if (name == 'dangerWarnWorkOccur') url = apiIndex.dangerWarnWorkOccur.viewDangerWarnWorkOccur;
			else if (name == 'aiDistinguish') url = apiIndex.aiDistinguish.viewAiDistinguish;
			else if (name == 'siteStatistic') url = apiIndex.siteStatistic.viewSiteStatistic;
			else if (name == 'accidentStatistic') url = apiIndex.accidentStatistic.viewAccidentStatistic;
			else if (name == 'site') url = apiIndex.site.viewSite;
			else if (name == 'user') url = apiIndex.user.viewUser;
			else if (name == 'menu') url = apiIndex.menu.viewMenu;
			else if (name == 'roleGroup') url = apiIndex.roleGroup.viewRoleGroup;
			else if (name == 'commCode') url = apiIndex.commCode.viewCommCode;
			else if (name == 'object') url = apiIndex.object.viewObject;
			else if (name == 'objectTarget') url = apiIndex.objectTarget.viewObjectTarget;
			else if (name == 'warnWork') url = apiIndex.warnWork.viewWarnWork;
			else if (name == 'cctv') url = apiIndex.cctv.viewCctv;
			else if (name == 'basicAiConfig') url = apiIndex.basicAiConfig.viewBasicAiConfig;
			else if (name == 'cctvAiConfig') url = apiIndex.cctvAiConfig.viewCctvAiConfig;
			else if (name == 'apiCallErrLog') url = apiIndex.apiCallErrLog.viewApiCallErrLog;
			else if (name == 'cctvDangerAreaConfig') url = apiIndex.cctvDangerAreaConfig.viewCctvDangerAreaConfig;
			else if (name == 'siteTrespassConfig') url = apiIndex.siteTrespassConfig.viewSiteTrespassConfig;
			else if (name == 'objectDangerOccurStatistic') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatistic;
			else if (name == 'objectDangerOccurStatisticBar') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticBar;
			else if (name == 'objectDangerOccurStatisticBar2') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticBar2;
			else if (name == 'objectDangerOccurStatisticLine2') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticLine2;
			else if (name == 'objectWarnWorkOccurStatisticBar') url = apiIndex.objectWarnWorkOccurStatistic.viewObjectWarnWorkOccurStatisticBar;
			else if (name == 'tree') url = apiIndex.tree.viewTree;
			else if (name == 'dashboard') url = apiIndex.dashboard.viewDashboard;
			else if (name == 'settingPushAlarm') url = apiIndex.settingPushAlarm.viewSettingPushAlarm;
			else if (name == 'speaker') url = apiIndex.speaker.viewSpeaker;
			else if (name == 'speakerSoundsrc') url = apiIndex.speakerSoundsrc.viewSpeakerSoundsrc;
			else if (name == 'speakerCctv') url = apiIndex.speakerCctv.viewSpeakerCctv;
			else if (name == 'registerUser') url = apiIndex.registerUser.viewRegisterUser;
			else if (name == 'cctvBySafeManager') url = apiIndex.cctvBySafeManager.viewCctvBySafeManager;
			else if (name == 'speakerAiConfig') url = apiIndex.speakerAiConfig.viewSpeakerAiConfig;
			else if (name == 'aiConfigDistigshSutbDgreCctv') url = apiIndex.aiConfigDistigshSutbDgreCctv.viewAiConfigDistigshSutbDgreCctv;
			else if (name == 'company') url = apiIndex.company.viewCompany;
			else if (name == 'grpCode') url = apiIndex.grpCode.viewGrpCode;
			else if (name == 'target') url = apiIndex.target.viewTarget;
			else if (name == 'exemplaryCase') url = apiIndex.exemplaryCase.viewExemplaryCase;
			else if (name == 'notice') url = apiIndex.notice.viewNotice;
			// topmenu에서 page이동 시 commonCodeList가 존재하지 않으면 commonCodeList 로드
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

			if (!commonCodeList || commonCodeList.length == 0) {
				await this.getCommCodeList();
			}

			if (!loginUserInfo || loginUserInfo.length == 0) {
				this.getLoginUser();
			}

			await this.$axios
				.post(url, {})
				.then(
					function (r) {
						if (r.data) {
							let data = r.data;
							if (data.token) delete data.token;

							// session clear 진행 시 commonCodeList까지 없어지는 것 방지
							const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
							const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

							/**
							 * session storage 비우기 (session 데이터 계속 쌓이는것 방지)
							 * 뒤로가기 시 각 vue에서 main으로 이동되게 처리
							 * 뒤로가기 허용 시 sessionStorage.clear(); 삭제
							 *  */
							sessionStorage.clear();

							if (name == 'dashboard') {
								localStorage.clear();
								localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								localStorage.setItem(name + 'PageParam', JSON.stringify(data));
								window.open(data.viewUrl);
							} else {
								sessionStorage.setItem('commonCodeList', JSON.stringify(commonCodeList));
								sessionStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								sessionStorage.setItem(name + 'PageParam', JSON.stringify(data));
								location.href = data.viewUrl;
							}
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		async getCommCodeList() {
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			if (!commonCodeList || commonCodeList.length == 0) {
				await this.$axios
					.post(apiIndex.commCode.inqCommCodeAllList, {})
					.then(
						function (r) {
							sessionStorage.setItem('commonCodeList', JSON.stringify(r.data));
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		hasAuthority(urlList) {
			const auth = urlList.some(r => this.loginUserMenuList.includes(r));
			return auth;
		},
		logout() {
			// session storage 비우기
			sessionStorage.clear();
			// vue-cookies 제거
			VueCookies.remove('V2_ACCESS_TOKEN');
			location.href = '/login';
		},
		// 현장 변경
		changeSiteId() {
			let loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
			if (!loginUserInfo || loginUserInfo.length == 0) {
				this.getLoginUser();

				loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
			}
			// selectbox에 값에 필요한 현재 계정의 현장 정보를 조회
			this.$axios
				.post(apiIndex.login.inqChangeSiteIds, { userId: this.loginUserId, userRoleCd: loginUserInfo.userRoleCd })
				.then(
					function (r) {
						// 입력한 아이디와 비밀번호가 일치하는 회원이면 response 받은 현장 정보를 저장
						if (r.data) {
							// 셀렉트 박스 내용을 현재 로그인할 유저의 현장들로 세팅
							this.selectOption = r.data;

							// 로그인 할 계정의 현장이 2개 이상이면 모달을 호출
							if (r.data.length > 1) {
								$('.modal[sites]').modal({
									backdrop: 'static',
									show: true,
									keyboard: true,
								});
							}
							// 현장이 1개면 바로 로그인 처리
							else {
								this.selectSite = 0;
								this.selectBtn();
							}
						}
						// 현장이 0개일때
						else {
							this.selectSite = 0;
							this.selectBtn();
						}
					}.bind(this),
				)
				.catch(error => {
					alert(error.response.data.message);
				});
		},
		// 현장 선택 버튼 이벤트
		selectBtn() {
			// 로그인 유저의 현장이 0개일때
			if (!this.selectOption.length) return alert('소속된 현장이 없습니다.\n관리자에게 문의해주시기 바랍니다.');

			// 선택된 현장 값 세팅 후 form으로 넘기기
			if (this.selectSite === '') return alert('현장을 선택해 주십시오');

			let selectedSiteId = '';

			// 현장을 선택하면 여러 번 요청을 방지하기 위해 셀렉트 박스와 버튼을 disabled 처리
			$('[selectBtn]')
				.prop('disabled', true)
				.prepend("<i class='fa fa-spin fa-spinner'></i> ")
				.closest('.modal')
				.find('select')
				.prop('disabled', true);

			// hidden 타입으로 삽입된 현장 정보를 clear(로그인 실패할 경우 계속 쌓이는 걸 방지)
			$('#loginForm').find('input[type=hidden]').remove();

			// 현재 선택된 현장의 정보를 hidden 타입으로 삽입해 전달
			$.each(this.selectOption[this.selectSite ? this.selectSite : 0], function (k, v) {
				$('<input type=hidden>').attr({ name: k, value: v }).appendTo('#loginForm');
				if (k == 'siteId') selectedSiteId = v;
			});

			// selectbox에서 선택한 siteId 값을 jwt에 저장하기 위해 controller 한번 더 호출
			this.$axios
				.post(apiIndex.login.setUserSiteId, { siteId: selectedSiteId })
				.then(function (r) {
					window.location.href = r.data.redirectUrl;
				})
				.catch(error => {
					alert(error.response.data.message);
				});
		},
	},
};
</script>
