<template>
	<div>
		<Topmenu />
		<!-- Page container -->
		<div class="page-container">
			<!-- Page content -->
			<div class="page-content">
				<!-- Main content -->
				<div class="content-wrapper">
					<!-- Content area -->
					<div class="content">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Topmenu from './Topmenu';

export default {
	name: 'Main',
	computed: {
		...mapState(['sideBarOpen']),
	},
	components: {
		Topmenu,
	},
};
</script>
