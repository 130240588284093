var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "navbar-brand" }, [
      _vm.loginUserId != ""
        ? _c("a", { attrs: { href: _vm.mainUrl } }, [
            _c("img", {
              staticStyle: {
                height: "68px",
                "image-rendering": "-webkit-optimize-contrast"
              },
              attrs: { src: "/images/a-safe-logo.png", alt: "" }
            })
          ])
        : _vm._e(),
      _vm.loginUserId == ""
        ? _c("a", { attrs: { href: "/" } }, [
            _c("img", {
              staticStyle: {
                height: "68px",
                "image-rendering": "-webkit-optimize-contrast"
              },
              attrs: { src: "/images/a-safe-logo.png", alt: "" }
            })
          ])
        : _vm._e()
    ]),
    _c("div", { staticStyle: { clear: "both" }, attrs: { id: "topmenu" } }, [
      _c(
        "div",
        {
          staticClass: "navbar navbar-inverse",
          staticStyle: { border: "none", height: "48px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "navbar-collapse collapse",
              staticStyle: { height: "48px !important" },
              attrs: { id: "navbar-mobile" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "nav navbar-nav navbar-menu",
                  staticStyle: { "margin-left": "40px" }
                },
                [
                  _vm._m(0),
                  _vm.menuGubun == "02"
                    ? _c("ul", [
                        _vm.hasAuthority(["/viewAccidentPrevent.do"])
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: { "data-desc": "재해예방조치" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadView(
                                        "accidentPrevent",
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("재해예방조치")])]
                              )
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewDangerWarnWorkOccur.do",
                          "/aiDistinguish.do"
                        ])
                          ? _c("li", [
                              _vm._m(1),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority([
                                    "/viewDangerWarnWorkOccur.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "발생이력" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerWarnWorkOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("발생이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/aiDistinguish.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "AI판별이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "aiDistinguish",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("AI판별이력")])]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewTree.do",
                          "/viewSiteStatistic.do",
                          "/viewAccidentStatistic.do",
                          "/viewObjectDangerOccurStatistic.do",
                          "/viewObjectDangerOccurStatisticBar.do",
                          "/viewObjectDangerOccurStatisticLine2.do",
                          "/viewObjectDangerOccurStatisticBar2.do"
                        ])
                          ? _c("li", [
                              _vm._m(2),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewTree.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "현장별사용자조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "tree",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("현장별 사용자 조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSiteStatistic.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "현장별통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "siteStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("현장별통계")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewAccidentStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "재해유형별통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "accidentStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("재해유형별통계")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "상황별 발생 추이"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("상황별 발생 추이")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatisticBar.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "상황별 발생 건수"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatisticBar",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("상황별 발생 건수")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatisticLine2.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc":
                                                "상황별 발생 추이(본사)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatisticLine2",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("상황별 발생 추이(본사)")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatisticBar2.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc":
                                                "상황별 발생 건수(본사)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatisticBar2",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("상황별 발생 건수(본사)")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority(["/viewExemplaryCase.do"])
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: { "data-desc": "모범사례" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadView(
                                        "exemplaryCase",
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("모범사례")])]
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.hasAuthority([
                                  "/viewCompany.do",
                                  "/viewSite.do",
                                  "/viewUser.do",
                                  "/viewRegisterUser.do",
                                  "/viewSettingPushAlarm.do",
                                  "/viewNotice.do",
                                  "/viewMenu.do",
                                  "/viewRoleGroup.do",
                                  "/viewGrpCode.do",
                                  "/viewCommCode.do",
                                  "/viewApiCallErrLog.do",
                                  "/viewObject.do",
                                  "/viewTarget.do",
                                  "/viewObjectTarget.do",
                                  "/viewWarnWork.do",
                                  "/viewBasicAiConfig.do",
                                  "/viewAiConfigDistigshSutbDgreCctv.do",
                                  "/viewCctvDangerAreaConfig.do",
                                  "/viewCctvAiConfig.do",
                                  "/viewSpeakerAiConfig.do",
                                  "/viewCctv.do",
                                  "/viewSpeaker.do",
                                  "/viewSpeakerSoundsrc.do",
                                  "/viewSpeakerCctv.do",
                                  "/viewCctvBySafeManager.do"
                                ]),
                                expression:
                                  "\n\t\t\t\t\t\t\t\thasAuthority([\n\t\t\t\t\t\t\t\t\t'/viewCompany.do',\n\t\t\t\t\t\t\t\t\t'/viewSite.do',\n\t\t\t\t\t\t\t\t\t'/viewUser.do',\n\t\t\t\t\t\t\t\t\t'/viewRegisterUser.do',\n\t\t\t\t\t\t\t\t\t'/viewSettingPushAlarm.do',\n\t\t\t\t\t\t\t\t\t'/viewNotice.do',\n\t\t\t\t\t\t\t\t\t'/viewMenu.do',\n\t\t\t\t\t\t\t\t\t'/viewRoleGroup.do',\n\t\t\t\t\t\t\t\t\t'/viewGrpCode.do',\n\t\t\t\t\t\t\t\t\t'/viewCommCode.do',\n\t\t\t\t\t\t\t\t\t'/viewApiCallErrLog.do',\n\t\t\t\t\t\t\t\t\t'/viewObject.do',\n\t\t\t\t\t\t\t\t\t'/viewTarget.do',\n\t\t\t\t\t\t\t\t\t'/viewObjectTarget.do',\n\t\t\t\t\t\t\t\t\t'/viewWarnWork.do',\n\t\t\t\t\t\t\t\t\t'/viewBasicAiConfig.do',\n\t\t\t\t\t\t\t\t\t'/viewAiConfigDistigshSutbDgreCctv.do',\n\t\t\t\t\t\t\t\t\t'/viewCctvDangerAreaConfig.do',\n\t\t\t\t\t\t\t\t\t'/viewCctvAiConfig.do',\n\t\t\t\t\t\t\t\t\t'/viewSpeakerAiConfig.do',\n\t\t\t\t\t\t\t\t\t'/viewCctv.do',\n\t\t\t\t\t\t\t\t\t'/viewSpeaker.do',\n\t\t\t\t\t\t\t\t\t'/viewSpeakerSoundsrc.do',\n\t\t\t\t\t\t\t\t\t'/viewSpeakerCctv.do',\n\t\t\t\t\t\t\t\t\t'/viewCctvBySafeManager.do',\n\t\t\t\t\t\t\t\t])\n\t\t\t\t\t\t\t"
                              }
                            ]
                          },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  mouseover: function($event) {
                                    return _vm.setSubMenuLocation()
                                  }
                                }
                              },
                              [_c("span", [_vm._v("관리")])]
                            ),
                            _c(
                              "div",
                              {
                                ref: "navSubMultiple",
                                staticClass: "Nav_Sub_Multiple"
                              },
                              [
                                _vm.hasAuthority([
                                  "/viewCompany.do",
                                  "/viewSite.do",
                                  "/viewUser.do",
                                  "/viewRegisterUser.do"
                                ])
                                  ? _c("div", { staticClass: "mng_menu" }, [
                                      _c("ul", [
                                        _vm._m(3),
                                        _vm.hasAuthority(["/viewCompany.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "업체관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "company",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("업체관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewSite.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "현장관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "site",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("현장관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewUser.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "사용자관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "user",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("사용자관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewRegisterUser.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "사용자가입승인"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "registerUser",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("사용자가입승인")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority([
                                  "/viewNotice.do",
                                  "/viewMenu.do",
                                  "/viewRoleGroup.do",
                                  "/viewGrpCode.do",
                                  "/viewCommCode.do",
                                  "/viewApiCallErrLog.do",
                                  "/viewSettingPushAlarm.do"
                                ])
                                  ? _c("div", { staticClass: "mng_menu" }, [
                                      _c("ul", [
                                        _vm._m(4),
                                        _vm.hasAuthority(["/viewNotice.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "공지사항"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "notice",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("공지사항")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewMenu.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "메뉴코드관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "menu",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("메뉴코드관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewRoleGroup.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "권한그룹관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "roleGroup",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("권한그룹관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewGrpCode.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "공통그룹코드관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "grpCode",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("공통그룹코드관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewCommCode.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "공통코드관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "commCode",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("공통코드관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewApiCallErrLog.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "API호출오류로그조회"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "apiCallErrLog",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "API호출오류로그조회"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewSettingPushAlarm.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "푸시알림관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "settingPushAlarm",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("푸시알림관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority([
                                  "/viewObject.do",
                                  "/viewTarget.do",
                                  "/viewObjectTarget.do",
                                  "/viewWarnWork.do"
                                ])
                                  ? _c("div", { staticClass: "mng_menu" }, [
                                      _c("ul", [
                                        _vm._m(5),
                                        _vm.hasAuthority(["/viewObject.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "대상물관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "object",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("대상물관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewTarget.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "조치대상관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "target",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("조치대상관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewObjectTarget.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "대상물조치대상관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "objectTarget",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("대상물조치대상관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewWarnWork.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "위험대상물조합관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "warnWork",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("위험대상물조합관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority([
                                  "/viewBasicAiConfig.do",
                                  "/viewAiConfigDistigshSutbDgreCctv.do",
                                  "/viewCctvDangerAreaConfig.do",
                                  "/viewCctvAiConfig.do",
                                  "/viewSpeakerAiConfig.do",
                                  "/viewSiteTrespassConfig.do"
                                ])
                                  ? _c("div", { staticClass: "mng_menu" }, [
                                      _c("ul", [
                                        _vm._m(6),
                                        _vm.hasAuthority([
                                          "/viewCctvAiConfig.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "CCTV 대상물 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "cctvAiConfig",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("CCTV 대상물 설정")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewBasicAiConfig.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "위험 판단 기준 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "basicAiConfig",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "위험 판단 기준 설정"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewAiConfigDistigshSutbDgreCctv.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "AI-threshold 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "aiConfigDistigshSutbDgreCctv",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("AI-threshold 설정")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewCctvDangerAreaConfig.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "위험지역 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "cctvDangerAreaConfig",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("위험지역 설정")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewSiteTrespassConfig.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "침입탐지 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "siteTrespassConfig",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("침입탐지 설정")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewSpeakerAiConfig.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "스피커대상물 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "speakerAiConfig",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("스피커대상물 설정")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority([
                                  "/viewCctv.do",
                                  "/viewSpeaker.do",
                                  "/viewSpeakerSoundsrc.do",
                                  "/viewSpeakerCctv.do",
                                  "/viewCctvBySafeManager.do"
                                ])
                                  ? _c("div", { staticClass: "mng_menu" }, [
                                      _c("ul", [
                                        _vm._m(7),
                                        _vm.hasAuthority(["/viewCctv.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "CCTV관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "cctv",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("CCTV관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewCctvBySafeManager.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "CCTV 설치장소 변경"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "cctvBySafeManager",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("CCTV 설치장소 변경")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority(["/viewSpeaker.do"])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc": "스피커관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "speaker",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("스피커관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewSpeakerCctv.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "스피커-CCTV 관계 설정"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "speakerCctv",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "스피커-CCTV 관계 설정"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.hasAuthority([
                                          "/viewSpeakerSoundsrc.do"
                                        ])
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    "data-desc":
                                                      "스피커음원관리"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadView(
                                                        "speakerSoundsrc",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("스피커음원관리")
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _c("li", [
                          _vm._m(8),
                          _c("div", { staticClass: "hidden-ul" }, [
                            _c("ul", [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      "data-desc": "dashboard",
                                      target: "_blank"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("dashboard", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장 모니터링")])]
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.menuGubun == "01"
                    ? _c("ul", [
                        _vm.hasAuthority([
                          "/viewDangerOccur.do",
                          "/viewWarnWorkOccur.do"
                        ])
                          ? _c("li", [
                              _vm._m(9),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewDangerOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험발생이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("위험발생이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewDangerOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험발생이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerOccurVideo",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험발생이력-비디오")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "주의작업이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWorkOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("주의작업이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "주의작업이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWorkOccurVideo",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("주의작업이력-비디오")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewTree.do",
                          "/viewObjectDangerOccurStatistic.do"
                        ])
                          ? _c("li", [
                              _vm._m(10),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewTree.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "현장별사용자조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "tree",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("현장별 사용자 조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물별위험통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물별위험통계-Line")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물별위험통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatisticBar",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물별위험통계-Bar")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewNotice.do",
                          "/viewCompany.do",
                          "/viewSite.do",
                          "/viewUser.do",
                          "/viewMenu.do",
                          "/viewRoleGroup.do"
                        ])
                          ? _c("li", [
                              _vm._m(11),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewNotice.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: { "data-desc": "공지사항" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "notice",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("공지사항")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCompany.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: { "data-desc": "업체관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "company",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("업체관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSite.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "현장관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "site",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("현장관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewUser.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "사용자관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "user",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("사용자관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewRegisterUser.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "사용자가입승인"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "registerUser",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("사용자가입승인")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewMenu.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "메뉴코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "menu",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("메뉴코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewRoleGroup.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "권한그룹관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "roleGroup",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("권한그룹관리")])]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewGrpCode.do",
                          "/viewCommCode.do",
                          "/viewObject.do",
                          "/viewTarget.do",
                          "/viewObjectTarget.do",
                          "/viewWarnWork.do",
                          "/viewCctv.do",
                          "/viewBasicAiConfig.do",
                          "/viewCctvAiConfig.do",
                          "/viewApiCallErrLog.do",
                          "/viewCctvDangerAreaConfig.do",
                          "/viewSiteTrespassConfig.do"
                        ])
                          ? _c("li", [
                              _vm._m(12),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewGrpCode.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              "data-desc": "그룹코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "grpCode",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("그룹코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCommCode.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "공통코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "commCode",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("공통코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewObject.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "object",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("대상물관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewTarget.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              "data-desc": "조치대상관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "target",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("조치대상관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewObjectTarget.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물조치대상관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectTarget",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물조치대상관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWork.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험대상물조합관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWork",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험대상물조합관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCctv.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "CCTV관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctv",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("CCTV관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCctvAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "CCTV 대상물 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("CCTV 대상물 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewBasicAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험 판단 기준 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "basicAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험 판단 기준 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewApiCallErrLog.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "API호출오류로그조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "apiCallErrLog",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("API호출오류로그조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewCctvDangerAreaConfig.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험지역 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvDangerAreaConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험지역 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewSiteTrespassConfig.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "침입탐지 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "siteTrespassConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("침입탐지 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSpeaker.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "스피커관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "speaker",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("스피커관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSpeakerSoundsrc.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "스피커음원관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "speakerSoundsrc",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("스피커음원관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSpeakerCctv.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc":
                                                "스피커-CCTV 관계 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "speakerCctv",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("스피커-CCTV 관계 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSpeakerAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "스피커대상물 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "speakerAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("스피커대상물 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewCctvBySafeManager.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "CCTV 설치장소 관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvBySafeManager",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("CCTV 설치장소 관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewAiConfigDistigshSutbDgreCctv.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "AI-threshold 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "aiConfigDistigshSutbDgreCctv",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("AI-threshold 설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-desc": "dashboard",
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loadView("dashboard", $event)
                                }
                              }
                            },
                            [_c("span", [_vm._v("모니터링")])]
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "ul",
                {
                  staticClass: "nav navbar-nav navbar-right",
                  staticStyle: { height: "48px" }
                },
                [
                  _vm.loginUserId != ""
                    ? _c("li", { staticClass: "dropdown dropdown-user" }, [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle",
                            attrs: {
                              "data-toggle": "dropdown",
                              "aria-expanded": "true"
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-user-tie" }),
                            _c("span", [_vm._v(_vm._s(_vm.loginUserId))]),
                            _c("i", { staticClass: "caret" })
                          ]
                        ),
                        _c(
                          "ul",
                          { staticClass: "dropdown-menu dropdown-menu-right" },
                          [
                            _c("li", [
                              _c("a", { on: { click: _vm.logout } }, [
                                _c("i", { staticClass: "icon-switch2" }),
                                _vm._v(" 로그아웃 ")
                              ]),
                              _c("a", { on: { click: _vm.changeSiteId } }, [
                                _c("i", { staticClass: "icon-sync" }),
                                _vm._v(" 현장변경 ")
                              ])
                            ])
                          ]
                        )
                      ])
                    : _c("li", { staticClass: "dropdown dropdown-user" }, [
                        _vm._m(13),
                        _vm._m(14)
                      ])
                ]
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        { ref: "modal", staticClass: "modal fade", attrs: { sites: "" } },
        [
          _c("div", { staticClass: "modal-dialog modal-sm" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(15),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectSite,
                        expression: "selectSite"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { "font-size": "16px" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectSite = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                    _vm._l(_vm.selectOption, function(option, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: index } },
                        [_vm._v(" " + _vm._s(option.siteNm) + " ")]
                      )
                    })
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary pull-right",
                    attrs: { selectBtn: "" },
                    on: { click: _vm.selectBtn }
                  },
                  [_vm._v("확인")]
                )
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/renew-main" } }, [
        _c("i", { staticClass: "icon-paragraph-justify3" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [_c("span", [_vm._v("이력조회")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("조회/통계")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("조직관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("시스템관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("대상물관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("AI관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("기기관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [_c("span", [_vm._v("모니터링")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("위험발생이력")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("조회/통계")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [_c("span", [_vm._v("조직관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("AI/데이터관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: { "data-toggle": "dropdown", "aria-expanded": "true" }
      },
      [
        _c("i", { staticClass: "icon-user-tie" }),
        _c("span", [_vm._v("Anonymous")]),
        _c("i", { staticClass: "caret" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "dropdown-menu dropdown-menu-right" }, [
      _c("li", [
        _c("a", { attrs: { href: "/login" } }, [
          _c("i", { staticClass: "icon-switch2" }),
          _vm._v(" 로그인 ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("현장 선택")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }